@import "./mixins.scss";
@import "./colors.scss";
@import "./genericStyles.scss";

//Input.js
.textInputComponent {
	min-width: 200px;
	padding: 10px;
	div.form-item {
		position: relative;
		display: block;
		.formLabel {
			margin-bottom: 5px;
		}
	}

	.MuiSvgIcon-root {
		position: absolute;
		right: 10px;
		bottom: 10px;
		&:hover {
			cursor: pointer;
			color: $orange;
		}
	}

	.MuiCircularProgress-root {
		position: absolute;
		right: 10px;
		bottom: 10px;
		width: 20px !important;
		height: 20px !important;
	}

	input.form-style,
	.react-geocoder > input,
	textarea.form-style {
		color: #8a8a8a;
		display: block;
		width: 100%;
		border: none;
		outline: none;
		position: static;
		background-color: transparent;
		font-family: "HelveticaNeue", "Arial", sans-serif;
		font-size: 100%;
		padding: 0;
	}
	textarea.form-style {
		resize: vertical;
		min-height: 260px;
		height: 260px;
		padding: 16px 5%;
		&.no-resize {
			resize: unset;
		}
	}
}

//BLM Banner
.blackLivesMatter {
	background: #000000;
	border-radius: 10px;
	margin: 0px auto 10px;
	max-width: 1440px;
	padding: 20px;
	display: block;

	h1 {
		color: #ffffff;
		font-size: 28px;
		line-height: 1;
	}

	&:hover {
		cursor: pointer;
	}
}

//Navbar.js
.navBar {
	position: sticky;
	// left: 50%;
	// transform: translateX(-50%);
	padding-top: 10px;
	padding-bottom: 10px;
	top: 0;
	width: 100%;

	margin: 0 auto;
	// background: #f85807;
	// box-shadow: -1px 3px 4px 1px rgba(0, 0, 0, 0.23);
	background: $websiteBackgroundColor;
	transition: box-shadow 0.1s ease-in-out;
	ul {
		@include flexbox;
		@include justify-content(flex-end);
		@include align-items(center);
		padding: $navbarPaddingTop 20px;
		margin: 0 auto;

		li:first-child {
			margin-left: 0;
			margin-right: auto;
			padding: 0;
		}
		li {
			border-radius: 20px;

			.homeLogo {
				@include flexbox;
				@include align-items(center);
				h1 {
					margin-left: 10px;
				}
				img {
					height: 40px;
				}
			}
			a {
				color: #1b1a23;
			}
			&.notCurrent {
				a {
					color: #fefefe;
					font-size: 16px;
				}
				p {
					font-size: 16px;
					margin-left: 10px;
				}
			}
			&.current {
				a {
					text-decoration: underline;
				}
			}
			&:not(.navSearch) {
				z-index: 1;
			}

			.avatar {
				border-radius: 50%;
				width: 40px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	h1 {
		font-size: 18px;
	}

	.navBarBasket {
		@include flexbox;
		@include justify-content(center);
		@include align-items(center);
		padding: 10px;
		border: 1px solid $white;
		border-radius: 10px;

		span {
			margin-left: 10px;
			font-size: 18px;
			color: $white;
		}

		svg {
			font-size: 16px;
			color: $white;
		}

		&:hover {
			background-color: $white;
			svg,
			span {
				color: $websiteBackgroundColor;
			}
		}
	}

	&.border {
		padding: 5px 30px;
		box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.3);
	}

	.iconButton {
		@include flexbox;
		@include justify-content(center);
		@include align-items(center);
		.MuiSvgIcon-root {
			color: $white;
			font-size: 40px;
		}
	}

	&.homepage {
		top: 0;
		background-color: #111111;
	}

	&.active {
		box-shadow: 0 7px 16px 0 $black;
	}

	@include mobile {
		ul {
			li {
				.homeLogo {
					img {
						// height: 40px;
					}
				}
			}
		}
	}
}

//Divider Line
.dividerLine {
	border-top: 2px solid $black;
}

.Footer {
	@include flexbox;
	@include justify-content(center);
	padding: 30px 0 20px;
	color: $white;
	margin: auto auto 20px;
	font-size: 16px;
}

.siteMap {
	@include flexbox;
	.section {
		h2 {
			margin-bottom: 20px;
		}
		ul {
			padding: 10px 0;
			li {
				padding: 20px 0;
			}
		}
	}
}

.react-calendar {
	margin: 0 auto;
}

//NO SCROLLBAR!!!!!!

/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noScrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

//react-slick
.slick-slider {
	width: 100%;
	.slick-prev {
		left: 20px;
		z-index: 2;
	}
	.slick-next {
		right: 20px;
		z-index: 2;
	}

	.slick-prev,
	.slick-next {
		&:before {
			display: none;
			font-size: 35px;
			color: $black;
			opacity: 1;
		}
	}
	.slick-dots {
		position: static;
		li {
			button {
				&:before {
					font-size: 12px;
				}
			}
		}
	}

	.slick-list {
		&:hover {
			cursor: grab;
		}
		&:active {
			cursor: grabbing;
		}
	}
}
