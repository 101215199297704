$gold: #e2c283;
$red: #ff5f5f;
$orange: #ffa36e;
$color-sunset-orange: #ff7d61;
$black: #0c0c0c;
$white: #fff;
$whiteTransparent: #ffffff9c;
$websiteBackgroundColor: #111111; //#fcfafa;
$globalBorderStyle: 2px solid white;
$placeholderBackgroundColor: #2d2d2d;

$secondaryTextColor: #c3c3c3;
$thirdTextColor: #8e8e8e;

//variables
$navbarPaddingTop: 5px;
