@keyframes fadeInBottom {
	from {
		opacity: 0;
		transform: translateY(30%);
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeInBottomModal {
	from {
		opacity: 0;
		transform: translateY(30%);
	}
	to {
		opacity: 1;
	}
}

@keyframes slidingDiv {
	0% {
		left: 0;
	}
	100% {
		left: -100%;
	}
}

@keyframes slideContainer {
	0% {
		transform: translateX(50%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes loaderShine {
	0% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

// loaderShine 2s ease infinite

// background: linear-gradient(90deg,var(--el-skeleton-color) 25%,var(--el-skeleton-to-color) 37%,var(--el-skeleton-color) 63%);
// background-size: 400% 100%;
