@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

//HomePageModal

.eventFinder {
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
	position: static;
	width: 100%;
	position: relative;
	z-index: 1;
	padding: 0 10px 10px;
	// transform: scaleY(0);

	.textInputComponent {
		.formLabel {
			color: $black;
			font-size: 22px;
		}
		&.focus {
			border-color: #333;
		}

		//date picker
		.MuiIconButton-sizeMedium {
			width: 100%;
			.MuiSvgIcon-root {
				font-size: 36px;
				color: $black;
			}
		}
	}

	.navbarMapboxGeocoder {
		@include flexbox;
		@include align-items(center);
		position: relative;
		width: 100%;

		.MuiSvgIcon-root {
			font-size: 28px;
			color: $black;
			&:hover {
				cursor: pointer;
			}
		}

		.react-geocoder-results {
			position: absolute;
			top: 103px;
			background: $websiteBackgroundColor;
			max-height: 235px;
			overflow-y: scroll;
			width: 100%;
			left: 0;
			padding: 15px 10px;
			border-radius: 15px;
			border: 1px solid #333;

			&::-webkit-scrollbar {
				display: none;
			}

			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */

			.react-geocoder-item {
				// margin: 5px 0;
				line-height: 1.2;
				padding: 8px;
				border-radius: 5px;
				font-size: 14px;
				color: $white;

				&:hover {
					cursor: pointer;
					color: #333333;
					border-color: #333333;
				}
			}
		}
	}

	.searchButton {
		&:hover {
			cursor: pointer;
			p {
				color: #ffffff;
			}
		}
	}

	@include mobile {
		.textInputComponent {
			.formLabel {
				font-size: 18px;
			}
		}

		.searchButton {
			padding: 15px 25px;
			p {
				font-size: 22px;
			}
		}
	}
}
