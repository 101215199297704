@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.navSearch {
	margin: 0 auto;
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;

	.background {
		width: 0;
		height: 0;
		background-color: transparent;
		-webkit-transition: background-color 0.2s ease;
		transition: background-color 0.2s ease;
	}

	.navbar__search {
		position: relative;
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
		padding: 5px 0 5px;
		top: calc(#{$navbarPaddingTop} + 6px);

		border-radius: 30px;
		background-color: $white;
		border: 2px solid $white;

		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
		transition-property: top, max-width;
		-webkit-transform-property: top, max-width;

		.eventFinder {
			height: 0;
			overflow: hidden;
			padding: 0;
		}

		&__inner {
			width: 100%;
			@include flexbox;
			@include align-items(center);
			padding-right: 5px;

			&__text {
				padding-left: 15px;
				font-size: 14px;
				color: $secondaryTextColor;
			}

			&__button {
				background-color: $black;
				padding: 5px 7px;
				border-radius: 50%;
				margin: 0 0 0 auto;

				svg {
					font-size: 22px;
					color: $white;
				}
			}
		}

		&:hover {
			border-color: #808080;
			cursor: pointer;
		}

		@include laptop {
			max-width: 300px;
		}

		@include mobile {
			max-width: 185px;
		}
	}

	&.active {
		top: 0;
		left: 0;
		height: 100vh;

		.background {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: relative;
			background-color: #0c0c0cb3;
		}

		.navbar__search {
			z-index: 1;
			position: absolute;
			// box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.23);
			top: 20vh;
			left: 50%;
			transform: translateX(-50%);

			&__inner {
				height: 0;
				overflow: hidden;
			}

			.eventFinder {
				height: unset;
				overflow: visible;
				padding: 0 10px 10px;
			}

			&:hover {
				border-color: $white;
			}

			@include mobile {
				max-width: 300px;
			}
		}
	}
}
