@import "../mixins.scss";
@import "../colors.scss";

.loginModalAlone {
	.orDivider {
		@include flexbox;
		@include align-items(center);
		@include justify-content(space-between);
		width: 100%;
		font-size: 16px;
		margin: 20px 0;
		padding: 0 20px;
		color: $whiteTransparent;
		span {
			height: 1px;
			background-color: $whiteTransparent;
			width: calc(50% - 30px);
		}
	}
}
