@import "./mixins.scss";
@import "./colors.scss";
body {
	background-color: $websiteBackgroundColor;
	#profile-menu {
		.MuiMenu-paper {
			background-color: $websiteBackgroundColor;
			border: none;
			ul {
				li {
					color: $white;
					&:hover {
						background-color: $black;
					}
				}
			}
		}
	}
}

.loadingPage {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	width: 100%;
	height: calc(100vh - 70px);
}
