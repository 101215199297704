@import "./colors.scss";
@import "./mixins.scss";
@import "./keyframes.scss";

.fadeInBottom {
	@include cssanimation;
	animation-name: fadeInBottom;
}

.topBanner {
	height: 300px;
	background-size: cover;
	background-position: center;
}

.maxWidth {
	max-width: 1440px;
	margin: 0 auto;
}

.info {
	@include flexbox;
	@include align-items(center);
	@include justify-content(space-between);
	width: 100%;
	margin: auto auto 0;
	.infoBlock {
		@include flexbox;
		@include align-items(center);
		@include flex-wrap(wrap);
		padding: 20px 0;
		color: black;
		border: 2px solid black;
		border-radius: 7px;
		text-align: center;
		height: 100px;
		width: 100px;
		backdrop-filter: blur(6px);
		h3 {
			font-size: 28px;
			width: 100%;
		}
		p {
			font-size: 18px;
			width: 100%;
			margin-top: 0;
		}

		&.goTo {
			@include flexbox;
			@include align-items(center);
			@include justify-content(center);
			a {
				color: $black;
			}
			svg {
				width: 40px;
				height: 40px;
			}
			&:hover {
				cursor: pointer;
				border-color: $red;
				color: $red;
				a {
					color: $red;
				}
			}
		}
	}
}
