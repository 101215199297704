@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.spotifyButton {
	> div {
		> div {
			@include flexbox;
			@include align-items(center);
		}

		> div:nth-of-type(1),
		> div:nth-of-type(2) {
			display: none !important;
		}

		img {
			width: 27px;
			height: 27px;
			margin-right: 10px;
		}
	}
}
